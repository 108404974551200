import { AccountType, PaymentType, PlanGroupType, PlanSku, PromotionType } from 'core/constants';
import { ModelBase } from 'store/model-base';

export interface SimpleObject {
    name: string;
    value: string; 
}

export interface ChargeDetail {
    chargeAmount: number;
    chargeType: PlanSku;
    chargeFrequency: PaymentType;
    duration: number;
    startTerm: number;
    sequence: number;
    numberOfInstallments: number;
}

export interface ModifierDetails {
    modifierId: string;
    version: number;
    name: string;
    type: string;
    skuList?: string[];
    valueTotal: number;
    promotionPrice: number;
    enableDate: string;
    disableDate: string;
    priority: number;
    rollToPay: boolean;
    totalDuration: number;
}

export interface PriceDetails{
    priority: number;
    displayPrice: number;
    chargeDetails: ChargeDetail[];
    modifierDetails?: ModifierDetails[];
}

export interface Prices {
    listPrice: number;
    retailPrice: number;
    currency: string;
    startQuantity: number;
    endQuantity: number;
    priceOptions: PriceDetails[];
}

export interface SKUList {
    skuCode: string;
    prices: Prices[];
    description: string;
    enableDate: string;
    disableDate: string;
    quantityPricingType: string;
    attributes: SimpleObject[];
    modifiers?: [];
}

export class InsurancePlanVariant extends ModelBase {
    public offerId: string;
    public name: string;
    public category: string;
    public subCategories: PlanGroupType[];
    public defaultSku: string;
    public accountType: AccountType;
    public skuList: SKUList[];
    public enabled: boolean;
    public metadata: SimpleObject[];
    public minimumQuantity: number; 
    public maximumQuantity: number;
    public enableDate: string;
    public disableDate: string;
    public description: string;
    public productFamily: string;
    public integrationType: string;
    public instantRemovalIndicator: boolean;
    public partnerGroup: string;
    public productRank: number;
    public childOffers: [];
    public associations: [];
    public displayGroups: [];
   
    public get isValidVariant(): boolean {
        return this.offerId && this.skuList?.length > 0;
    }

    public get isXMC(): boolean {
        return this.isValidVariant && this.subCategories?.some(subCategory => subCategory === PlanGroupType.XMC);
    }

    public get isACP(): boolean {
        return this.isValidVariant && this.subCategories?.some(subCategory => subCategory === PlanGroupType.ACP);
    }

    public get xmcMonthlyPrice(): number {
        return this.isXMC ? this.skuList[0]?.prices[0]?.priceOptions[0]?.displayPrice : 0;
    }

    public get acpPrice(): number {
        return this.isACP ? this.skuList[0]?.prices[0]?.priceOptions[0]?.displayPrice : 0;
    }

    public get catalogItemId(): string {
        return this.isValidVariant ? this.skuList[0]?.attributes.find(attribute => attribute.name === 'catalogItemId')?.value : '';
    }

    public get oneTimeCharge(): number {
        return this.acpPrice ? this.acpPrice : 0;
    }

    public get displayPrice(): string {
        return `$${Math.round(this.xmcMonthlyPrice)}/mo`;
    }

    public get acpDisplayPrice(): string {
        return `$${this.oneTimeCharge?.toFixed(2)}`;
    }

    public get isXmcPromoEligible(): boolean {
        if (this.isXMC && this.skuList[0]?.prices[0]?.priceOptions[0]?.modifierDetails?.length > 0) {
            return this.skuList[0]?.prices[0]?.priceOptions[0]?.modifierDetails.find(modifier => modifier.type === PromotionType.XMC_PROMOTION_TYPE) ? true : false;
        }
    }

    public get xmcPromoDetails(): ModifierDetails {
        return this.isXmcPromoEligible ? this.skuList[0]?.prices[0]?.priceOptions[0]?.modifierDetails.find(modifier => modifier.type === PromotionType.XMC_PROMOTION_TYPE) : undefined;
    }
}