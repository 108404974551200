
import { ACP_NAME, PlanSku, XMC_NAME, XMPP_NAME } from 'core/constants';
import { PlanVariant } from './plan';

export class ProtectionPlanVariant extends PlanVariant {
    
    public get isXMPP(): boolean {
        return this.id && this.id !== PlanSku.NO_XMPP;
    }

    public get isXMC(): boolean {
        return this.id && this.id !== PlanSku.NO_XMC;
    }

    public get isACP(): boolean {
        return this.id && this.id !== PlanSku.NO_ACP;
    }
  
    public get monthlyPrice(): number {
        return this.isXMC ? this.price.monthlyCharge : 0;
    }

    public get cartInfo(): string {
        return `${XMPP_NAME}, ${this.displayPrice}`;
    }

    public get xmcCartInfo(): string {
        return `${XMC_NAME}, ${this.displayPrice}`;
    }

    public get acpCartInfo(): string {
        return `${ACP_NAME}, ${this.acpDisplayPrice}`;
    }

}
