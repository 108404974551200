import { ChildModel, ModelBase } from 'store/model-base';
import { Plan, PlanGroup, PriceOption, ProtectionPlan, ProtectionPlanGroup, ProtectionPlanVariant } from 'store/plan/models';
import { Availability, PlanGroupType, PlanSku, PlanType } from 'core/constants';
import { Util } from 'services/util';
import { PromoEligibleArgs, Promotion } from 'store/promotions/models';
import { TradeInDeviceDetail } from 'store/trade-ins/models';
import { LineSelectType } from 'buy/constants';
import { TradeInInfo } from 'store/promotions/models/trade-in-info';

export class Variant extends ModelBase {
    public availabilityDate: Date;
    public capacity: string;
    public catalogItemId: string;
    public color: ProductColor;
    public currency: string;
    public currencyString: string;
    public dateAdded: Date;
    public images: VariantImage;
    public plans: PlanGroup[];
    public prices: PriceOption[];
    public skuCode: string;
    public status: Availability;

    public prepaidPromo: PriceOption;
    public discountPromo: PriceOption;
    public subsidyPromo: PriceOption;
    public extendedPromo: Promotion;
    public extendedPromoDollar: Promotion;
    public extendedPromoPercent: Promotion;
    public tradeInPromo: Promotion;
    
    public financedPlan: PriceOption;
    public payFullPlan: PriceOption;
    public xmcVariant: ProtectionPlanVariant;
    public acpVariant: ProtectionPlanVariant;
    public xmppVariant: ProtectionPlanVariant;
    public newLineTip: TradeInInfo[];
  
    private tradeinInfoDevices: TradeInDeviceDetail[]; // This will give all the combined devices details from the tradeinInfo from TIPS promotions.
    private tradeInPromos: Promotion[];
    private extendedPromos: Promotion[];

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public static create<T extends ModelBase>(initData: ApiResponse): T {
        const toReturn: TransformedData = initData;

        toReturn.images = toReturn.images || {};
        toReturn.images.hero = Variant.imageDefaults(toReturn.images.hero);
        toReturn.images.primary = Variant.imageDefaults(toReturn.images.primary);
        toReturn.images.alternate = Variant.imageDefaults(toReturn.images.alternate);

        toReturn.capacity = toReturn.capacity ? toReturn?.capacity?.toUpperCase() : '';

        toReturn.financedPlan = initData.prices ? initData.prices.find((plan: PriceOption) => PriceOption.create<PriceOption>(plan).isFinanced) : undefined;
        toReturn.payFullPlan = initData.prices ? initData.prices.find((plan: PriceOption) => PriceOption.create<PriceOption>(plan).isFull) : undefined;

        const variant: Variant = super.create<Variant>(toReturn);

        const protectionPlans: ProtectionPlanGroup = variant.plans ? <ProtectionPlanGroup> variant.plans.find((planOption: PlanGroup) => planOption.type === PlanType.ProtectionPlan) : undefined;
        const protectionOptions: ProtectionPlan = protectionPlans && protectionPlans.options[0];
        variant.xmppVariant = protectionOptions && protectionOptions.variants[0];
        
        if (protectionPlans) {
            const xmcProtectionOptions: ProtectionPlan = protectionPlans.options ? protectionPlans.options.find((plan: Plan) => plan.planGroup === PlanGroupType.XMC) : undefined;
            if (xmcProtectionOptions) {
                variant.xmcVariant = xmcProtectionOptions && xmcProtectionOptions.variants[0];
            }
            const acpProtectionOptions: ProtectionPlan = protectionPlans && protectionPlans.options?.find((plan: Plan) => plan.planGroup === PlanGroupType.ACP);
            if (acpProtectionOptions) {
                variant.acpVariant = acpProtectionOptions && acpProtectionOptions.variants[0];
            }
        }

        variant.prepaidPromo = variant.prices.find((price: PriceOption) => price.isPrepaidPromo);
        variant.discountPromo = variant.prices.find((price: PriceOption) => price.isDollarDiscount || price.isPercentPromo);
        variant.subsidyPromo = variant.prices.find((price: PriceOption) => price.isSubsidyPromo && price.isFinanced);
        variant.extendedPromo = variant.prices.find((price: PriceOption) => price.isExtendedPromo) && variant.prices.find((price: PriceOption) => price.isExtendedPromo).extendedPromo;
        variant.extendedPromos = variant.prices.filter((price: PriceOption) => price.isExtendedPromo).flatMap((priceOption: PriceOption) => priceOption.extendedPromo);
        variant.tradeInPromo = variant.prices.find((price: PriceOption) => price.isTradeInPromo) && variant.prices.find((price: PriceOption) => price.isTradeInPromo).tradeInPromo;

        const tradeInPromoPrices: PriceOption[] = variant.prices.filter((price: PriceOption) => price.isTradeInPromo);
        variant.tradeInPromos = tradeInPromoPrices.flatMap((priceOption: PriceOption) => priceOption.tradeInPromo);
        
        variant.tradeinInfoDevices = [];
        variant.tradeInPromos.forEach((promo: Promotion) => {
            variant.tradeinInfoDevices = variant.tradeinInfoDevices.concat(promo.tradeinInfo?.flatMap((info: TradeInInfo) => info.devices));
       
        });

        variant.newLineTip = variant.tradeInPromos.filter((tip: Promotion) => !tip.eligibilityServices.ndel).flatMap((promotion: Promotion) => promotion.tradeinInfo);
        
        return <T> <any> variant;
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'financedPlan',
            model: PriceOption
        }, {
            attrName: 'payFullPlan',
            model: PriceOption
        }, {
            attrName: 'extendedPromo',
            model: Promotion
        }, {
            attrName: 'tradeInPromo',
            model: Promotion
        }, {
            attrName: 'xmcVariant',
            model: ProtectionPlanVariant
        }, {
            attrName: 'xmppVariant',
            model: ProtectionPlanVariant
        }, {
            attrName: 'acpVariant',
            model: ProtectionPlanVariant
        }];
    }

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'prices',
            model: PriceOption
        }, {
            attrName: 'extendedPromos',
            model: Promotion
        }, {
            attrName: 'tradeInPromos',
            model: Promotion
        }, {
            attrName: 'tradeinInfoDevices',
            model: TradeInDeviceDetail
        }];
    }

    private static imageDefaults(image: Image): Image {
        const defaultImage: Image = { url: '', alt: '' };

        const imageData: Image = image || defaultImage;
        imageData.url = imageData.url || defaultImage.url;
        imageData.alt = imageData.alt || defaultImage.alt;

        return imageData;
    }

    constructor(variant: ApiResponse) {
        super(variant);

        this.availabilityDate = new Date(variant.availabilityDate);
        this.dateAdded = new Date(variant.dateAdded);
    }

    public get isPreorder(): boolean {
        return this.status === Availability.PREORDER;
    }

    public get isBackorder(): boolean {
        return this.status === Availability.BACKORDER;
    }

    public get isOutOfStock(): boolean {
        return this.status === Availability.OUT_OF_STOCK;
    }

    public get isInStock(): boolean {
        return this.status === Availability.AVAILABLE;
    }

    public getAllPromoValue(lineType: LineSelectType, paymentType: PlanSku): number {
        const promotions: Promotion[] = [...this.filterPromos(lineType, paymentType), ...this.filterTradeInPromos(lineType, paymentType)];
        const initialValue: number = promotions.length ? promotions[0].valueTotal : 0;

        return promotions.flatMap((promo: Promotion) => promo.valueTotal).reduce((previous: number, current: number) => Math.max(previous, current), initialValue);
    }

    public get availabilityText(): string {
        if (this.isPreorder) {
            return 'Available for Preorder';
        } else if (this.isBackorder) {
            return 'This item is on Backorder';
        } else if (this.isOutOfStock) {
            return 'Out of Stock';
        }

        return 'In Stock';
    }

    public get availabilityFormatedDate(): string {
        return Util.dateFormat(this.availabilityDate.toString(), 'MMM dd, yyyy');
    }

    public get displayImages(): Image[] {
        const images: Image[] = [];

        const variantImages: VariantImage = this.images;
        if (variantImages.primary && variantImages.primary.url) {
            images.push(variantImages.primary);
        }

        if (variantImages.hero && variantImages.hero.url) {
            images.push(variantImages.hero);
        }

        if (variantImages.alternate && variantImages.alternate.length) {
            variantImages.alternate.forEach((image: Image) => {
                if (image.url) {
                    images.push(image);
                }
            });
        }

        return images;
    }

    public get isFinanced(): boolean {
        return this.originalFinancePrice !== this.financePrice;
    }

    public get isPayFull(): boolean {
        return this.originalFullPrice !== this.fullPrice;
    }

    public get originalFullPrice(): number {
        return this.payFullPlan ? this.payFullPlan.originalPrice : 0;
    }

    public get fullPrice(): number {
        return this.payFullPlan ? this.payFullPlan.total : 0;
    }

    public get originalFinancePrice(): number {
        return this.financedPlan ? this.financedPlan.originalPrice : 0;
    }

    public get financePrice(): number {
        return this.financedPlan ? this.financedPlan.total : 0;
    }

    public get hasTradeInPromo(): boolean {
        return Boolean(this.tradeInPromos.length);
    }

    public getPaymentPlanById(id: string): PriceOption {
        return id === PlanSku.FINANCED ? this.financedPlan : this.payFullPlan;
    }
    
    public getTradeInPromoById(id: string): Promotion {
        return this.tradeInPromos.find((promo: Promotion) => promo.id === id);
    }

    public getTradeInPromo(promoEligibility: PromoEligibleArgs): Promotion {
        return this.tradeInPromos.find((promo: Promotion) => promo.isPromoEligible(promoEligibility));
    }

    public getExtendedPromo(promoEligibility: PromoEligibleArgs): Promotion {
        return this.extendedPromos.find((promo: Promotion) => promo.isPromoEligible(promoEligibility));
    }

    public getNewLineExtendedPromos(): Promotion[] {
        return this.extendedPromos.filter((promo: Promotion) => promo.isExtendedPromoNewLine);
    }
    
    public get downPayment(): number {
        return this.financedPlan ? this.financedPlan.downPayment : 0;
    }

    public get financedPromoPrice(): number {
        return this.financedPlan && (this.financedPlan.isSubsidyPromo || this.isExtendedPromoPhase2) ? this.financedPlan.dollarPromoPrice : this.originalFinancePrice;
    }

    public get fullPromoPrice(): number {
        return this.payFullPlan && this.payFullPlan.isSubsidyPromo ? this.payFullPlan.promotionPrice : this.originalFullPrice;
    }

    public get isExtendedPromoPhase2(): boolean {
        return this.extendedPromo && this.extendedPromo.hasApiData && this.extendedPromo.isExtendedPromoPhase2;
    }

    public get isTradeInPromo(): boolean {
        return this.isTradeInPromo;
    }
    
    public get tradeInPromoTradeInInfo(): TradeInInfo[] {
        return this.tradeInPromos.flatMap(
            (promotion: Promotion) => promotion.tradeinInfo);
    }

    public getNewLineTradeInPromos(): Promotion[] {
        return this.tradeInPromos.filter((promo: Promotion) => promo.isTradeInPromoNewLine);
    }

    public getExistingLineExtendedPromos(): Promotion[] {
        return this.extendedPromos.filter((promo: Promotion) => promo.isExtendedPromoExistingLine);
    }

    public getExistingLineTradeInPromos(): Promotion[] {
        return this.tradeInPromos.filter((promo: Promotion) => promo.isTradeInPromoExistingLine);
    }

    public getTradeInPromoValue(lineType: LineSelectType, paymentType: PlanSku): number {
        const promotions: Promotion[] = this.filterTradeInPromos(lineType, paymentType);
        const initialValue: number = promotions.length ? promotions[0].valueTotal : 0;

        return promotions.flatMap((promo: Promotion) => promo.valueTotal).reduce((previous: number, current: number) => Math.max(previous, current), initialValue);
    }  

    //This is used to get the best promo for that group id from all tiers of all applicable promo
    public getTradeInPromoValueForGroupId(lineType: LineSelectType, paymentType: PlanSku, groupId: string): number {
        const promotions: Promotion[] = this.filterTradeInPromos(lineType, paymentType);
        const tierValues: number[] = [];

        promotions.forEach(promotion => {
            tierValues.push(this.getTierMaxValue(promotion, groupId));
        });

        return tierValues.reduce((previous: number, current: number) => Math.max(previous, current), 0);
    }

    public getPromoValue(lineType: LineSelectType, paymentType: PlanSku): number {
        const promotions: Promotion[] = this.filterPromos(lineType, paymentType);
        const initialValue: number = promotions.length ? promotions[0].valueTotal : 0;

        return promotions.flatMap((promo: Promotion) => promo.valueTotal).reduce((previous: number, current: number) => Math.max(previous, current), initialValue);
    } 

    public get tradeInPromosGroupId(): string[] {
        return this.tradeinInfoDevices.flatMap((device: TradeInDeviceDetail) => device.groupId);
    }

    
    public getAllOriginalPrice(lineType: LineSelectType, paymentType: PlanSku): number {
        const promotions: Promotion[] = [...this.filterPromos(lineType, paymentType), ...this.filterTradeInPromos(lineType, paymentType)];

        return promotions.length ? this.originalFinancePrice : 0;
    }

    public getOriginalPrice(lineType: LineSelectType, paymentType: PlanSku): number {
        const promotions: Promotion[] = this.filterPromos(lineType, paymentType);

        return promotions.length ? this.originalFinancePrice : 0;
    }

    public getAllActualPrice(lineType: LineSelectType.NEW, paymentType: PlanSku): number {
        const promotions: Promotion[] = [...this.filterPromos(lineType, paymentType), ...this.filterTradeInPromos(lineType, paymentType)];
        if (this.subsidyPromo && paymentType === PlanSku.FINANCED) {
            promotions.push(this.subsidyPromo.promotions[0]);
        }

        const initialValue: number = promotions.length ? promotions[0].promotionPrice : paymentType === PlanSku.FINANCED ? this.originalFinancePrice : this.originalFullPrice;
        
        return promotions.flatMap((promo: Promotion) => promo.promotionPrice).reduce((previous: number, current: number) => Math.min(previous, current), initialValue);
    }

    public getActualPrice(lineType: LineSelectType, paymentType: PlanSku): number {
        const promotions: Promotion[] = this.filterPromos(lineType, paymentType);
        if (this.subsidyPromo && paymentType === PlanSku.FINANCED) {
            promotions.push(this.subsidyPromo.promotions[0]);
        }

        const initialValue: number = promotions.length ? promotions[0].promotionPrice : paymentType === PlanSku.FINANCED ? this.originalFinancePrice : this.originalFullPrice;
        
        return promotions.flatMap((promo: Promotion) => promo.promotionPrice).reduce((previous: number, current: number) => Math.min(previous, current), initialValue);
    }

    public getDeviceByMake(deviceDetails: TradeInDeviceDetail[]): MakeModelDetails[] {
        const makeModelDetails: MakeModelDetails[] = [];
        const uniqueMake: string[] = [...new Set(deviceDetails.map(item => item.make))];
        uniqueMake.forEach((make: string) => {
            const sameMakeDevices: TradeInDeviceDetail[] = deviceDetails.filter(deviceDetail => deviceDetail.make === make);
            makeModelDetails.push({ make: make, modelList: sameMakeDevices.flatMap((element: TradeInDeviceDetail) => element.model) });
        });

        return makeModelDetails;
    }

    //Get Unique sorted trade in details from multiple promotions
    public processModalDetails(modalDetails: TradeInModalDetails[]): TradeInModalDetails[] {
        const newModalDetails: TradeInModalDetails[] = [];
        const uniqueTierValues = [...new Set(modalDetails.map(modalDetail => modalDetail.tierValue))];
        uniqueTierValues.forEach(tierValue => {
            const sameTierValueDetails: TradeInModalDetails[] = modalDetails.filter(modalDetail => modalDetail.tierValue === tierValue);
            const tradeInInfoModal: TradeInModalDetails = {
                tier: sameTierValueDetails[0].tier,
                tierValue: sameTierValueDetails[0].tierValue,
                tierHeader: sameTierValueDetails[0].tierHeader,
                makeModelDetails: this.combineMakeModelDetails(sameTierValueDetails)
            };
            newModalDetails.push(tradeInInfoModal);
        });

        newModalDetails.sort((current: TradeInModalDetails, next: TradeInModalDetails) => next.tierValue - current.tierValue);

        return newModalDetails;
    }

    //Get Unique make and models from multiple promotions
    public combineMakeModelDetails(sameTierValueDetails: TradeInModalDetails[]): MakeModelDetails[] {
        const allModelDetails: MakeModelDetails[] = sameTierValueDetails.flatMap((tradeInModalDetails: TradeInModalDetails) => tradeInModalDetails.makeModelDetails);
        const uniqueMakeModelDetails: MakeModelDetails[] = [];
        const uniqueMake: string[] = [...new Set(allModelDetails.flatMap((makeModelDetail: MakeModelDetails) => makeModelDetail.make))];
        uniqueMake.forEach((make: string) => {
            const modals: string[] = [];
            allModelDetails.filter((makeModelDetail: MakeModelDetails) => makeModelDetail.make === make).forEach((makeModelDevices: MakeModelDetails) => {
                makeModelDevices.modelList.forEach(element => {
                    if (!modals.includes(element)) {
                        modals.push(element);
                    }
                });
            });
            uniqueMakeModelDetails.push({ make: make, modelList: modals });
        });

        return uniqueMakeModelDetails;
    }

    private filterTradeInPromos(lineType: LineSelectType, paymentType: PlanSku): Promotion[] {
        return this.tradeInPromos
            .filter((promo: Promotion) => !lineType || (promo.isPromoEligible({ newLine: lineType === LineSelectType.NEW, ndel: lineType === LineSelectType.NDEL })))
            .filter((promo: Promotion) => !paymentType || (promo.isPromoEligible({ isDpp: paymentType === PlanSku.FINANCED, pif: paymentType === PlanSku.PAY_IN_FULL })));
    }

    private filterPromos(lineType: LineSelectType, paymentType: PlanSku): Promotion[] {
        return this.extendedPromos
            .filter((promo: Promotion) => !lineType || (promo.isPromoEligible({ newLine: lineType === LineSelectType.NEW, ndel: lineType === LineSelectType.NDEL })))
            .filter((promo: Promotion) => !paymentType || (promo.isPromoEligible({ isDpp: paymentType === PlanSku.FINANCED, pif: paymentType === PlanSku.PAY_IN_FULL })));
    }

    private getTierMaxValue(promotion: Promotion, groupId: string): number {
        let value: number = 0;

        promotion.tradeinInfo.forEach(tradeInInfo => {
            tradeInInfo.devices.forEach(device => {
                if (device.groupId === groupId) {
                    value = value > 0 ? Math.max(value, tradeInInfo.valueTotal) : tradeInInfo.valueTotal;
                }
            });
        });

        return value;
    }
}
