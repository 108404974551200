import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SessionStorage } from '../storage/session';
import { Util } from 'services/util';
import { IXMOptions } from 'core/interfaces';
import { ApiChannel, CONFIG_TOKEN, maskApi, maskFields, StorageToken } from 'core/constants';
import { CimaToken } from '../cima/token';
import { environment } from '../../../../environments/environment';
import { getCurrentEnvironment, logAndHandleMessage } from 'core/services/log/LogHelper';
import { map } from 'rxjs/operators';
import { LogTimeFields, OperationType } from 'services/log/model/LogFields';
import { MaskPipe } from 'shared/pipes/mask';

@Injectable()
export class ApiResourceInterceptor implements HttpInterceptor {
    private config: IXMOptions;
    private cimaToken: CimaToken;
    private inbentaSession: string = Util.generateUuid();
    private isProductionEnv: boolean = environment.production;
    private kibanaUrl: string;
    private kibanaKey: string;
    private sessionStorage: SessionStorage;
    private maskPipe: MaskPipe;

    constructor(cimaToken: CimaToken, @Inject(CONFIG_TOKEN) config: IXMOptions, maskPipe: MaskPipe, sessionStorage: SessionStorage) {
        Object.assign(this, { cimaToken, config, maskPipe, sessionStorage });
    }

    public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
        let newRequest: HttpRequest<object> = request;
        if (!request.url.startsWith('/')) {
            const message: string = `partial url must start with a /, given \'${request.url}\'`;
            /* eslint-disable no-console */
            console.error(message);
            /* eslint-enable no-console */         

            return throwError(message);
        }

        if (this.isProductionEnv) {
            this.kibanaUrl = 'https://prod.core-gateway.api-business.comcast.com/logging-api';
            this.kibanaKey = 'CYgtuUmITK3BCCqq9U0Tx87ERCjYHL8YaYWVDxRr';
        } else {
            this.kibanaUrl = 'https://dev.core-gateway.np.api-business.comcast.com/logging-api';
            this.kibanaKey = 'cLmI91Zox71sObfWw5nL53X7kOPz9Rk6aCLJgHuD';
        }

        const channelCode: string = request.headers.get('apiChannel');
        newRequest = newRequest.clone({ headers: request.headers.delete('apiChannel') }); // should not be used after this interceptor

        if (request.method !== 'GET') {
            newRequest = newRequest.clone({ setHeaders: { 'Content-Type': 'application/json' } });
        }

        switch (channelCode) {
            case ApiChannel.CMS:
                newRequest = newRequest.clone({ url: `${this.config.CMS_URL}${request.url}` });
                break;

            case ApiChannel.SITECORE_URL:
                if (this.config.SITECORE_URL) {
                    newRequest = this.generateCmsParams(newRequest.clone({
                        url: this.config.SITECORE_URL,
                        setParams: {
                            contentId: request.url.replace(/^\/*/, '')
                        }
                    }));
                } else {
                    newRequest = newRequest.clone({ url: `${this.config.CMS_URL}${request.url}` });
                }
                break;

            case ApiChannel.GATEWAY:
                newRequest = this.appendEpHeaders(newRequest.clone({
                    url: `${this.config.GATEWAY_BASE_URL}${request.url}`,
                    setHeaders: {
                        Authorization: `Bearer ${this.cimaToken.accessToken}`,
                        'X-Channel': 'WEB',
                        'X-global-tracking-id': Util.generateUuid()
                    }
                }));
                break;
            case ApiChannel.GATEWAY_FOR_GUID:
                newRequest = this.appendEpHeaders(newRequest.clone({
                    url: `${this.config.GATEWAY_BASE_URL}${request.url}`,
                    setHeaders: {
                        Authorization: `Bearer ${this.cimaToken.accessToken}`,
                        'X-Channel': 'WEB'
                    }
                }));
                break;
            case ApiChannel.BOOTSTRAP_API:
                const trackingId: string = getCurrentEnvironment() !== 'local' ? this.sessionStorage.get(StorageToken.VISITOR_SESSION_ID) : Util.generateUuid();
                newRequest = newRequest.clone({
                    url: `${this.config.BOOTSTRAP_API_URL}${request.url}`,
                    setHeaders: {
                        'x-api-key': `${this.config.BOOTSTRAP_X_API_KEY}`,
                        'X-Channel': 'WEB',
                        'tracking-id': trackingId
                    }
                });
                break;

            case ApiChannel.PCAT:
                newRequest = this.appendEpHeaders(newRequest.clone({ url: `${this.config.PCAT_BASE_URL}${request.url}` }));
                break;

            case ApiChannel.PROXY:
                newRequest = newRequest.clone({ url: `${this.config.MSP_PROXY}${request.url}` });
                break;

            case ApiChannel.TIP_PROXY:
                newRequest = newRequest.clone({ url: `${this.config.TIP_PROXY}${request.url}` });
                break;

            case ApiChannel.AKAMAI_PROXY:
                newRequest = this.appendAkamaiProxyHeader(newRequest.clone({ url: `${this.config.AKAMAI_PROXY}${request.url}` }));
                break;

            case ApiChannel.INBENTA:
                newRequest = newRequest.clone({
                    url: `${this.config.MSP_PROXY}${request.url}`,
                    setHeaders: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    setParams: {
                        session_id: this.inbentaSession
                    }
                });
                break;
            case ApiChannel.SESSION_API:
                newRequest = newRequest.clone({ url: `${this.config.VISITOR_SESSION_URL}${request.url}`,
                    setHeaders: {
                        'Content-Type': 'application/json' 
                    }
                });
                break;

            case ApiChannel.KIBANA_CODEBIG_API:
                newRequest = newRequest.clone({ url: `${this.kibanaUrl}${request.url}`,
                    setHeaders: {
                        'Content-Type': 'application/json',
                        'x-api-key': this.kibanaKey
                    }
                });
                break;

            case ApiChannel.ACCOUNT_REGISTRATION:
                newRequest = newRequest.clone({ url: `${this.config.ACCOUNT_URL}${request.url}`,
                    setHeaders: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.cimaToken.accessToken}`
                    }
                });
                break;

            case ApiChannel.BROADBAND_LABELS:
                newRequest = newRequest.clone({ url: `${this.config.BROADBAND_LABELS_URL}${request.url}`,
                    setHeaders: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${this.cimaToken.accessToken}`,
                        'tracking-id': Util.generateUuid16()
                    }
                });
                break;

            default:
                const message: string = 'missing HTTP Header \'apiChannel\'';
                /* eslint-disable no-console */
                console.error(message);
                /* eslint-enable no-console */

                return throwError(message);
        }

        // everyone accepts JSON
        newRequest = newRequest.clone({ setHeaders: { Accept: 'application/json' } });

        return this.handleLogging(next, newRequest, request.url, channelCode);
    }

    private handleLogging(next: HttpHandler, request: HttpRequest<object>, apiUrl: string, channelCode: string): Observable<HttpEvent<object>> {
        const startTimestamp = new Date().toISOString();
        
        return next.handle(request).pipe(map((event: HttpEvent<object>) => {
            if ((channelCode !== ApiChannel.CMS) && (channelCode !== ApiChannel.SITECORE_URL) && (!request.url.includes('logging-api'))) {
                const endTimestamp = new Date().toISOString();
                const logTimeFields: LogTimeFields = {
                    StartTimeUtc: startTimestamp,
                    EndTimeUtc: endTimestamp                
                };               
                    
                const globalTrackingId: string = request.headers.get('X-global-tracking-id');
                const sessionID: string = request.headers.get('sessionID');

                let response: string = undefined;

                if (maskApi.includes(apiUrl)) {
                    response = this.maskJSONValue(event);
                }                
                // eslint-disable-next-line max-len
                logAndHandleMessage({ sessionId: sessionID, globalTrackingId: globalTrackingId }, apiUrl, OperationType[request.method], undefined, JSON.stringify(request), response !== undefined ? JSON.stringify(response): JSON.stringify(event), logTimeFields);
            }

            return event;
        }));
    }
    /* eslint-disable @typescript-eslint/no-explicit-any */
    private maskJSONValue(event: HttpEvent<object>): any {
        let responseBody;
        if (event instanceof HttpResponse) {
            const data = JSON.parse(JSON.stringify(event.body));
            for (const key in data) {
                // eslint-disable-next-line
                if (typeof(data[key]) === 'object' && (data[key] !== null)) {
                    this.maskvalue(data[key]);
                } else {
                    if (maskFields.includes(key.toUpperCase())) {
                        const maskedValue = this.maskPipe.transform(data[key]);
                        data[key] = maskedValue;
                    }
                }
            }
            responseBody = data;
        }

        return responseBody;
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    private maskvalue(objectToMask: any): void {
        for (const key in objectToMask) {
            if (typeof (objectToMask[key]) === 'object') {
                this.maskvalue(objectToMask[key]);
            } else {
                if (maskFields.includes(key.toUpperCase())) {
                    const maskedValue = this.maskPipe.transform(objectToMask[key]);
                    objectToMask[key] = maskedValue;
                }
            }
        }
    }

    private generateCmsParams(newRequest: HttpRequest<object>): HttpRequest<object> {
        if (this.config.CMS_CDSID && this.config.CMS_ENV) {
            return newRequest.clone({
                setParams: {
                    env: this.config.CMS_ENV,
                    cdsid: this.config.CMS_CDSID
                }
            });
        } else if (this.config.SITECORE_SITE) {
            return newRequest.clone({
                setParams: {
                    sc_site: this.config.SITECORE_SITE
                }
            });
        }

        return newRequest;
    }

    private appendEpHeaders(newRequest: HttpRequest<object>): HttpRequest<object> {
        if (this.config.CATALOG_SOURCE && this.config.CATALOG_SCOPE) {
            return newRequest.clone({
                setHeaders: {
                    'X-Catalog-Source': this.config.CATALOG_SOURCE,
                    'X-Catalog-Scope': this.config.CATALOG_SCOPE
                }
            });
        }

        return newRequest;
    }

    private appendAkamaiProxyHeader(newRequest: HttpRequest<object>): HttpRequest<object> {
        if (this.config.AKAMAI_PROXY_HEADER_VALUE) {
            return newRequest.clone({
                setHeaders: {
                    'XM-TRAFFIC': this.config.AKAMAI_PROXY_HEADER_VALUE
                }
            });
        }

        return newRequest;
    }
}
