import { ChildModel, ModelBase } from 'store/model-base';
import { DeviceType, PlanGroupType, PlanType } from 'core/constants';
import { PlanGroup } from './group/plan-group';
import { RatePlanGroup } from './group/rate-group';
import { ProtectionPlanGroup } from './group/protection-group';
import { Plan } from './option/plan';
import { RatePlan } from './option/rate-plan';
import { ProtectionPlan } from './option/protection-plan';
import { RatePlanVariant } from './variant/rate';
import { ProtectionPlanVariant } from './variant/protection';

export class PlanCollection extends ModelBase {
    public static storeName: string = 'planCollection';

    public byTheGigVariant: RatePlanVariant;
    public unlimitedVariant: RatePlanVariant;
    public unlTabletVariant: RatePlanVariant;
    public rupVariants: RatePlanVariant[];
    public sharedVariants: RatePlanVariant[];
    public xmppVariant: ProtectionPlanVariant;
    public xmcVariant: ProtectionPlanVariant;
    public acpVariant: ProtectionPlanVariant;
    public smartWatchVariant: RatePlanVariant;
    public tabletVariants: RatePlanVariant[];
    public accountId: string;
    public category: DeviceType;

    private plans: PlanGroup[];

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public static create<T extends ModelBase>(initData: ApiResponse): T {
        const toReturn: PlanCollection = super.create<PlanCollection>(initData);

        const ratePlans: RatePlanGroup = <RatePlanGroup> toReturn.plans.find((planOption: PlanGroup) => planOption.type === PlanType.RatePlan);
      

        if (ratePlans) {
            const byTheGigOptions: RatePlan = ratePlans.options.find((plan: Plan) => plan.planGroup === PlanGroupType.BY_THE_GIG);
            const sharedOptions: RatePlan = ratePlans.options.find((plan: Plan) => plan.planGroup === PlanGroupType.BY_THE_GIG_SHARED);
            const unlimitedOptions: RatePlan[] = ratePlans.options.filter((plan: Plan) => plan.planGroup === PlanGroupType.UNLIMITED);
            
            const hasUnlimitedOptions: boolean = Boolean(unlimitedOptions && unlimitedOptions.length);
            if (hasUnlimitedOptions) {
                toReturn.unlimitedVariant = unlimitedOptions && unlimitedOptions[0].variants[0];
                unlimitedOptions.forEach((option: RatePlan) => {
                    toReturn.rupVariants.push(...option.variants);
                });
            }

            const tabletUnlOptions: RatePlan[] = ratePlans.options.filter((plan: Plan) => plan.planGroup === PlanGroupType.TABLET);

            const hasTabletUnlOptions: boolean = Boolean(tabletUnlOptions && tabletUnlOptions.length);
            if (hasTabletUnlOptions) {
                toReturn.unlTabletVariant = tabletUnlOptions && tabletUnlOptions[0].variants[0];
                tabletUnlOptions.forEach((option: RatePlan) => {
                    toReturn.tabletVariants.push(...option.variants);
                });
            }

            toReturn.byTheGigVariant = byTheGigOptions && byTheGigOptions.variants[0];
            toReturn.sharedVariants = sharedOptions && sharedOptions.variants.sort((variant1: RatePlanVariant, variant2: RatePlanVariant) => variant1.rank - variant2.rank);

            const smartWatchOptions: RatePlan = ratePlans.options.find((plan: Plan) => plan.planGroup === PlanGroupType.SMART_WATCH);
            if (smartWatchOptions) {
                toReturn.smartWatchVariant = smartWatchOptions && smartWatchOptions.variants[0];
            }
        }

        const protectionPlans: ProtectionPlanGroup = <ProtectionPlanGroup>toReturn.plans.find((planOption: PlanGroup) => planOption.type === PlanType.ProtectionPlan);
        const protectionOptions: ProtectionPlan = protectionPlans && protectionPlans.options[0];
        toReturn.xmppVariant = protectionOptions && protectionOptions.variants[0];
 
        if (protectionPlans) {
            const xmcProtectionOptions: ProtectionPlan = protectionPlans && protectionPlans.options.find((plan: Plan) => plan.planGroup === PlanGroupType.XMC);
            toReturn.xmcVariant = xmcProtectionOptions && xmcProtectionOptions.variants[0];

            const acpProtectionOptions: ProtectionPlan = protectionPlans && protectionPlans.options?.find((plan: Plan) => plan.planGroup === PlanGroupType.ACP);
            toReturn.acpVariant = acpProtectionOptions && acpProtectionOptions.variants[0];
        }

        toReturn.accountId = initData.accountId;

        return <T> <any> toReturn;
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'byTheGigVariant',
            model: RatePlanVariant
        }, {
            attrName: 'unlimitedVariant',
            model: RatePlanVariant
        }, {
            attrName: 'xmppVariant',
            model: ProtectionPlanVariant
        }, {
            attrName: 'xmcVariant',
            model: ProtectionPlanVariant
        }, {
            attrName: 'smartWatchVariant',
            model: RatePlanVariant
        }];
    }

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'plans',
            model: PlanGroup
        }, {
            attrName: 'sharedVariants',
            model: RatePlanVariant
        }, {
            attrName: 'rupVariants',
            model: RatePlanVariant
        }, {
            attrName: 'tabletVariants',
            model: RatePlanVariant
        }];
    }

    public get ratePlansGrandfather(): RatePlanVariant[] {
        return [ this.byTheGigVariant, this.unlimitedVariant ];
    }

    public get ratePlansNonGrand(): RatePlanVariant[] {
        return [ ...this.sharedVariants, ...this.rupVariants ];
    }

    public get protectionPlans(): ProtectionPlanVariant[] {
        return [ this.xmppVariant ];
    }

    public get xmcProtectionPlans(): ProtectionPlanVariant[] {
        return [ this.xmcVariant ];
    }

    public getRatePlanById(id: string): RatePlanVariant {
        return [ ...this.ratePlansGrandfather, ...this.ratePlansNonGrand ].find((plan: RatePlanVariant) => plan && plan.id === id);
    }

    public findSharedRatePlanById(id: string): RatePlanVariant {
        const bucketId: string = id.includes(':') ? id.split(':')[1] : id;

        return this.sharedVariants.find((variant: RatePlanVariant) => variant && variant.bucketId === bucketId);
    }

    public getRupPlanById(id: string): RatePlanVariant {
        if (this.rupVariants && this.rupVariants.length) {
            return this.rupVariants.find((plan: RatePlanVariant) => plan && plan.id === id);
        }
    }
    public getTabletPlanById(id: string): RatePlanVariant {
        if (this.tabletVariants && this.tabletVariants.length) {
            return this.tabletVariants.find((plan: RatePlanVariant) => plan && plan.id === id);
        }
    }
}