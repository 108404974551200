import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiChannel } from 'core/constants';

export interface HttpGenericOptions {
    // any other headers they want to add
    [key: string]: string | number | boolean;
}

export interface HttpOptions extends HttpGenericOptions {
    apiChannel?: ApiChannel;
    timeout?: number;
    storeAction?: string;
    handleCmsError?: boolean;
    Authorization?: string;
}

export function buildHeaders(opts: HttpOptions): HttpHeaders {
    return new HttpHeaders(filterData(opts));
}

export function buildParams(opts: HttpGenericOptions): HttpParams {
    return new HttpParams({ fromObject: filterData(opts) });
}

/* eslint-disable no-null/no-null */
function filterData(opts: HttpGenericOptions): { [key: string]: string } {
    // we need to cast everything to a string
    const objStrings: { [key: string]: string } = {};
    Object.entries(opts).forEach(([ key, value ]: [ string, string | number | boolean ]) => {
        if (value !== undefined && value !== null) {
            objStrings[key] = value.toString();
        }
    });

    return objStrings;
}
/* eslint-enable no-null/no-null */
