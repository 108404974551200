import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiChannel, CIMA_CHECK, CONFIG_TOKEN, DEFAULT_DERAIL_STATE, DEFAULT_USER_DETAIL, StorageToken } from 'core/constants';
import { StoreAction } from 'store/actions';
import { Account, BuyInfo, User } from 'store/user/models';

import { buildHeaders } from '../http/options';
import { DownPayment } from '../down-payment';
import { IXMOptions, UserDataStatus } from 'core/interfaces';
import { Fingerprint } from '../fingerprint';
import { UserStatus } from '../user-status';
import { Util } from '../util';
import { XmStore, XmStoreUtil } from '../store';
import { CimaToken } from './token';
import { CimaUrl } from './url';
import { SessionStorage } from 'services/storage/session';
import { logAndHandleError, logAndHandleMessage } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';
import { HttpError } from 'services/http/error';


const RESI_USER_TYPE: string = 'Residential';

@Injectable({
    providedIn: 'root'
})
export class CimaCore {
    private static PASSIVE_EVENT: string = 'OAUTH_EVENT';
    
    private RETRY_COUNT: number = 3;
    private config: IXMOptions;
    private downPayment: DownPayment;
    private http: HttpClient;
    private xmStore: XmStore;
    private fingerprint: Fingerprint;
    private userStatus: UserStatus;
    private cimaToken: CimaToken;
    private cimaUrl: CimaUrl;
    private refreshInterval: number;
    private sessionStorage: SessionStorage;

    constructor(
    @Inject(CONFIG_TOKEN) config: IXMOptions,
        downPayment: DownPayment,
        fingerprint: Fingerprint,
        http: HttpClient,
        xmStore: XmStore,
        userStatus: UserStatus,
        cimaToken: CimaToken,
        cimaUrl: CimaUrl,
        sessionStorage: SessionStorage
    ) {
        Object.assign(this, {
            config,
            downPayment,
            fingerprint,
            http,
            xmStore,
            userStatus,
            cimaToken,
            cimaUrl,
            sessionStorage
        });

        window.addEventListener('storage', this.storageListener.bind(this));
    }

    /**
     * Will check if the cima token was found in local storage and get one if it doesn't exist
     */
    public loadCimaToken(): Promise<string | void> {
        // we have a "code" from cima redirect which can then be used to get user access/refresh token
        if (this.cimaToken.code) {

            return this.getUserToken();
        } else if (this.cimaToken.accessToken) {
            this.setupRefreshToken('loadCimaToken');

            return Promise.resolve(this.cimaToken.accessToken);
        }

        return Promise.resolve(undefined);
    }

    /**
     * Public function that initiates the check to see if they are logged in with another comcast service
     */
    public tryPassiveAuthLogin(): void {
        if (!this.cimaToken.isLoggedIn) {
            this.passiveAuthIframe();
        }
    }

    /**
     * This is used to lazy load the account details and check if the user has an exist cart
     * NOTE: Only do this check if the user is logged in
     */
    public initUserData(): void {
        if (this.cimaToken.isLoggedIn) {
            // if buyInfo has already been called, no need to call account to get the first/last name
            this.xmStore.peekChildPromise<BuyInfo, User>('buyInfo', BuyInfo, User)
                .catch(() => XmStoreUtil.defaultCatch(this.xmStore.findChildPromise<Account, User>(StoreAction.GET_ACCOUNT_INFO, 'account', Account, User)).then((account: Account) => {
                    
                    if (!this.sessionStorage.hasKey(StorageToken.CBM_TEST_USER)) {
                        this.sessionStorage.set(StorageToken.CBM_TEST_USER, (account.user.email === DEFAULT_USER_DETAIL.EMAIL || account.user.email.includes(DEFAULT_USER_DETAIL.DOMAIN) ? 'true' : 'false' ));
                    }

                    let userData: UserDataStatus = this.sessionStorage.get(StorageToken.USER_DETAIL);

                    if (!userData) {
                        userData = {
                            status: account.state,
                            custGuid: account.custGuid,
                            accountType: account.buyInfoAccountType,
                            level: account.level,
                            isStubOffer: undefined
                        };
                        this.sessionStorage.set(StorageToken.USER_DETAIL, JSON.stringify(userData));
                    }

                    if (account.buyInfoAccountType) {
                        return;
                    }
                    //retry logic if account type is missing
                    for (let count = 1; count <= this.RETRY_COUNT; count++) {
                        setTimeout(() => {
                            XmStoreUtil.defaultCatch(this.xmStore.findChildPromise<Account, User>(StoreAction.GET_ACCOUNT_INFO, 'account', Account, User)).then(() => {
                                if (account.buyInfoAccountType) {

                                    return;
                                } else if (count === this.RETRY_COUNT) {
                                    //derail
                                    window.location.href = DEFAULT_DERAIL_STATE;
                                }
                            });
                        }, 10000);
                    }
                })
                );

            this.fingerprint.initialize();
            this.userStatus.watch();
            this.downPayment.loadAuthenticatedTps();
        }
    }

    /**
     * Check if the user is residential or business based on the CIMA ID token
     */

    public get isBusinessUser(): boolean {
        const idToken: string = this.cimaToken.idToken;

        return idToken && Util.decodeJWT(idToken) && Util.decodeJWT(idToken).user_type !== RESI_USER_TYPE;
    }

    /**
     * Get user access/refresh token from the "code" that we are given on redirect from cima //original auth api
     */
    private getUserToken(fromPassiveAuth: boolean = false): Promise<string | void> {
        const requestBody = {
            code: this.cimaToken.code,
            consumer: 'cbm',
            redirectUri: this.cimaUrl.apiRedirectUri(fromPassiveAuth)
        };
        const bootstrap_api_endpoint = '/v2/usertoken/codetoken';

        return this.http.post(bootstrap_api_endpoint, requestBody, {
            headers: buildHeaders({ apiChannel: ApiChannel.BOOTSTRAP_API })
        }).toPromise()
            .then((tokens: cimaBootstrapResponse) => {
                this.cimaToken.saveNewToken(tokens.data.accessToken, tokens.data.expiresIn, tokens.data.refreshToken, tokens.data.idToken);
                this.setupRefreshToken('getUserToken');
                this.sessionStorage.set(StorageToken.CBM_CIMA_CHECK, CIMA_CHECK.PASSED);
                logAndHandleMessage({ errors: tokens.messages, status: tokens.status }, bootstrap_api_endpoint, OperationType.POST, tokens.messages[0].description);
                this.sessionStorage.set(StorageToken.CBM_CIMA_CHECK, CIMA_CHECK.PASSED);

                return this.cimaToken.accessToken;
            })
            .catch((error: HttpError) => {
                logAndHandleError(error, bootstrap_api_endpoint, OperationType.POST, undefined, JSON.stringify(requestBody));
                this.sessionStorage.set(StorageToken.CBM_CIMA_CHECK, CIMA_CHECK.FAILED);

                Promise.resolve(undefined);
            });
    }
    
    /**
     * Get new access/refresh token using the saved refresh token
     */
    private getUserTokenFromRefresh(source: string): void {
        const requestBody = {
            refreshToken: this.cimaToken.refreshToken,
            consumer: 'cbm'
        };
        const bootstrap_api_endpoint = '/v2/usertoken/refreshtoken';

        this.http.post(bootstrap_api_endpoint, requestBody, {
            headers: buildHeaders({ apiChannel: ApiChannel.BOOTSTRAP_API })
        }).toPromise()
            .then((tokens: cimaBootstrapResponse) => {                
                this.cimaToken.saveNewToken(tokens.data.accessToken, tokens.data.expiresIn, tokens.data.refreshToken, tokens.data.idToken);                
                logAndHandleMessage({ errors: tokens.messages, status: tokens.status }, `${bootstrap_api_endpoint}-${source}`, OperationType.POST, tokens.messages[0].description);
                this.setupRefreshToken('getUserTokenFromRefresh');

                return this.cimaToken.accessToken;
            })
            .catch((error: HttpError) => {
                logAndHandleError(error, `${bootstrap_api_endpoint}-${source}`, OperationType.POST, undefined, JSON.stringify(requestBody));
                Promise.resolve(undefined);
            })
            .finally(() => {
                this.cimaToken.refreshTokenInProgress = false;                
            });
    }

    private setupRefreshToken(sourceName: string): void {
        this.clearInterval();

        if (this.cimaToken.refreshTokenInProgress) {
            return;
        }

        this.cimaToken.refreshTokenInProgress = true;
        this.refreshInterval = window.setInterval(() => {
            // if the expiration time has passed, need to refresh the token
            if (this.cimaToken.isExpired()) {
                this.clearInterval();
                this.getUserTokenFromRefresh(sourceName);
            }
        }, 10000);
    }

    private clearInterval(): void {
        if (this.refreshInterval) {
            window.clearInterval(this.refreshInterval);
            this.refreshInterval = undefined;
        }
    }

    private storageListener(event: StorageEvent): void {
        // only listen for our token
        if (event.key === StorageToken.CIMA) {
            if (!event.newValue) {
                this.cimaUrl.redirectToLogout();
            } else if (event.newValue !== event.oldValue) {
                // the case where another tab updated the token and we need to load the new token
                const foundNewToken: boolean = this.cimaToken.checkForNewTokens();
                if (foundNewToken) {
                    this.setupRefreshToken('storageListener');
                }
            }
        }
    }

    /**
     * Build an iframe that checks if the user is logged in with another comcast service
     */
    private passiveAuthIframe(): void {
        // set listener for passive auth
        window.addEventListener(CimaCore.PASSIVE_EVENT, this.passiveAuthListener.bind(this), false);

        const queryParams: string = Util.createQueryParams({
            client_id: this.config.CLIENT_ID,
            redirect_uri: this.cimaUrl.apiRedirectUri(true),
            prompt: 'none',
            response_type: 'code'
        });

        const authIframe: HTMLIFrameElement = window.document.createElement('iframe');
        authIframe.setAttribute('src', `${this.config.CIMA_BASE_URL}/oauth/authorize${queryParams}`);
        authIframe.setAttribute('name', 'oauth-iframe');
        authIframe.setAttribute('id', 'oauth-iframe');
        authIframe.setAttribute('style', 'height: 0; width: 0; border: 0; position: absolute;');
        authIframe.setAttribute('title', 'oauth-iframe');
        window.document.body.appendChild(authIframe);
    }

    /**
     * If the user is logged in with another comcast service, the code will get sent back here, which will allow
     * us to get the user cima token and then load any inital user data
     */
    private passiveAuthListener(event: CustomEvent): void {
        const code: string = event ? event.detail : '';
        if (code) {
            this.cimaToken.code = code;
            this.getUserToken(true).then(() => {
                this.initUserData();
            });
        }
    }
}
